import ApiService from "../../core/services/api.service";

const apiService = ApiService;

export default class AdminLoginService {
  #api = null;

  constructor() {
    this.#api = `admin`;
  }

  checkVerificationEnabled(data) {
    let url = `${this.#api}/check/verification-enabled`;
    return apiService.post(url, data);
  }

  verifyMfaVerificationCode(data) {
    let url = `${this.#api}/verify/mfa-verification-code`;
    return apiService.post(url, data);
  }

  verifyEmailVerificationCode(data) {
    let url = `${this.#api}/verify/email-verification-code`;
    return apiService.post(url, data);
  }

  requestEmailVerificationCode(data) {
    let url = `${this.#api}/request/verification-code`;
    return apiService.post(url, data);
  }
}
