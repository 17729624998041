<template>
  <div class="login-form login-signin">
    <!--begin::Signin-->
    <b-form
      class="kt-form"
      @submit.stop.prevent="onSubmit"
      v-if="!mfaAuthenticator && !emailAuthenticator && !multiAuthenticator"
    >
      <div class="d-flex flex-column-auto flex-column pb-13 pt-lg-0 pt-5">
        <!-- <a href="#" class="text-center mb-10">
          <img src="media/logos/logo-light.png" class="max-h-100px" alt="" />
        </a> -->
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Welcome to Absent Note
        </h3>
      </div>
      <div class="form-group">
        <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
        <div id="example-input-group-1" label="" label-for="example-input-1">
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            placeholder="Your Email or Username"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
            autocomplete="off"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5"
            >Password</label
          >
        </div>
        <div id="example-input-group-2" label="" label-for="example-input-2">
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            placeholder="Your Password"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
            autocomplete="off"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback"
            >Password is required.
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="pb-lg-0 pb-5">
        <router-link
          v-if="isNotVerified"
          class="btn btn-primary text-white mt-3 col-md-12"
          :to="{ name: 'verification' }"
          >{{ $t("AUTH.VERIFY.BUTTON") }}
        </router-link>

        <button
          v-if="!isNotVerified"
          type="submit"
          id="login_btn"
          ref="kt_login_signin_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
        >
          {{ $t("AUTH.LOGIN.BUTTON") }}
        </button>
      </div>
    </b-form>
    <!--end::Signin-->

    <!--begin::Multi Authenticator Form-->
    <b-form class="kt-form" v-if="multiAuthenticator">
      <div role="alert" class="alert alert-danger" v-if="errors.length > 0">
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>

      <b-form-group
        id="example-input-group-1"
        label-for="example-input-1"
        v-if="!verificationEmailBtn"
      >
        <b-form-input
          class="form-control form-control-solid h-auto py-5 px-6"
          id="example-input-1"
          name="example-input-1"
          @keypress.enter.native.prevent
          placeholder="MFA Verification Code"
          v-model="authenticator.mfa.verification_code"
          aria-describedby="input-1-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-1-live-feedback"
          >Verification Code
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-for="email-verification-code"
        v-if="verificationEmailBtn"
      >
        <b-form-input
          class="form-control form-control-solid h-auto py-5 px-6"
          name="email-verification-code"
          @keypress.enter.native.prevent
          placeholder="Email Verification Code"
          v-model="authenticator.email.verification_code"
          aria-describedby="input-1-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-1-live-feedback"
          >Email Verification Code
        </b-form-invalid-feedback>
      </b-form-group>

      <!--begin::Divider-->
      <div class="kt-login__divider">
        <div class="kt-divider">
          <span></span>
          <span
            class="kt-link kt-login__signup-link"
            @click="verificationEmailBtn = !verificationEmailBtn"
            >Try Alternative</span
          >
          <span></span>
        </div>
      </div>

      <div class="kt-login__actions">
        <span
          class="kt-login__signup-label"
          v-if="!verificationEmailBtn"
        ></span>
        <b-button
          v-if="verificationEmailBtn"
          type="button"
          @click="requestEmailVerificationCode"
          class="btn btn-warning btn-elevate"
          :disabled="emailVerificationMailRequested"
        >
          {{
            emailVerificationMailRequested
              ? "You can request again after 1 minute"
              : "Request Email Verification"
          }}
        </b-button>

        <b-button
          type="button"
          @click="verifyMultiAuthCode"
          ref="kt_login_signin_submit"
          class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >Verify
        </b-button>
      </div>
      <!--end::Action-->
    </b-form>
    <!--end::Multi Authenticator Form-->

    <!--begin::MFA Verification Form-->
    <b-form
      class="kt-form"
      @submit.stop.prevent="verifyMfaAuthenticator"
      v-if="mfaAuthenticator"
    >
      <div role="alert" class="alert alert-danger" v-if="errors.length > 0">
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>

      <b-form-group id="example-input-group-1" label-for="example-input-1">
        <b-form-input
          class="form-control form-control-solid h-auto py-5 px-6"
          id="example-input-1"
          name="example-input-1"
          placeholder="MFA Verification Code"
          v-model="authenticator.mfa.verification_code"
          aria-describedby="input-1-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-1-live-feedback"
          >MFA Verification Code
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="kt-login__actions">
        <span class="kt-login__signup-label"></span>
        <b-button
          type="submit"
          ref="kt_login_signin_submit"
          class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >Verify
        </b-button>
      </div>
      <!--end::Action-->
    </b-form>
    <!--end::MFA Verification Form-->

    <!--begin::Email Verification Form-->
    <b-form class="kt-form" v-if="emailAuthenticator">
      <div role="alert" class="alert alert-danger" v-if="errors.length > 0">
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>

      <b-form-group id="example-input-group-1" label-for="example-input-1">
        <b-form-input
          class="form-control form-control-solid h-auto py-5 px-6"
          id="example-input-1"
          name="example-input-1"
          placeholder="Email Verification Code"
          v-model="authenticator.email.verification_code"
          aria-describedby="input-1-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-1-live-feedback"
          >Verification Code
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="kt-login__actions">
        <span class="kt-login__signup-label"></span>
        <b-button
          type="button"
          @click="verifyEmailAuthenticator"
          id="verify_btn"
          ref="kt_login_signin_submit"
          class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >Verify
        </b-button>
      </div>
      <!--end::Action-->
    </b-form>
    <!--end::Email  Verification Form-->

    <vue-snotify></vue-snotify>
    <vue-recaptcha
      ref="invisibleRecaptcha"
      :sitekey="siteKey"
      @verify="onCaptchaVerified"
      :loadRecaptchaScript="true"
      size="invisible"
    ></vue-recaptcha>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { LOGIN } from "@/core/services/store/auth.module";
import VueRecaptcha from "vue-recaptcha";
import AdminLoginService from "@/service/auth/AdminLoginService";

const adminLoginService = new AdminLoginService();

export default {
  name: "Login",
  mixins: [validationMixin],
  components: { VueRecaptcha },
  data() {
    return {
      errors: [],
      isNotVerified: false,
      mfaAuthenticator: false,
      emailAuthenticator: false,
      multiAuthenticator: false,
      verificationEmailBtn: false,
      emailVerificationMailRequested: false,
      siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      setting: null,
      authenticator: {
        mfa: {
          verification_code: ""
        },
        email: {
          verification_code: ""
        }
      },
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },
  mounted: function() {
    this.redirectIfLoggedIn();
  },
  methods: {
    redirectIfLoggedIn() {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push({ name: "dashboard" });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    activateSubmitSpinner(btnId) {
      const submitButton = document.getElementById(btnId);
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    deactivateSubmitSpinner(btnId, seconds) {
      const submitButton = document.getElementById(btnId);
      setTimeout(() => {
        submitButton.classList.remove(
          "kt-spinner",
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, seconds * 1000);
    },

    setLogin(data) {
      this.$store
        .dispatch(LOGIN, data)
        // go to which page after successfully login
        .then(() => {
          this.$router.push({ name: "dashboard" });
        });
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return 0;
      }
    },
    onCaptchaVerified: function() {
      if (!this.$v.form.$anyError) {
        // set spinner to submit button
        this.activateSubmitSpinner("login_btn");
        adminLoginService
          .checkVerificationEnabled(this.form)
          .then(response => {
            this.errors = [];
            this.isNotVerified = false;
            this.deactivateSubmitSpinner("login_btn", 1);
            if (response.data.status === "NOT_FOUND") {
              this.$snotify.error(response.data.errors);
            } else if (response.data.status === "NOT_VERIFIED") {
              this.$snotify.error(response.data.errors);
              this.isNotVerified = true;
              this.deactivateSubmitSpinner("login_btn", 1);
            } else if (
              response.data.data.is_mfa_enabled === "1" &&
              response.data.data.is_email_authentication_enabled === "1"
            ) {
              this.multiAuthenticator = true;
            } else if (response.data.data.is_mfa_enabled === "1") {
              this.mfaAuthenticator = true;
            } else if (
              response.data.data.is_email_authentication_enabled === "1"
            ) {
              this.emailAuthenticator = true;
            } else {
              this.setLogin(this.form);
            }
          })
          .catch(() => {});
      }
    },
    verifyMfaAuthenticator() {
      if (this.authenticator.mfa.verification_code === "") {
        this.errors = [];
        this.$snotify.error("Please enter a verification code.");
      } else {
        this.errors = [];
        let data = this.form;
        data.verification_code = this.authenticator.mfa.verification_code;
        adminLoginService
          .verifyMfaVerificationCode(data)
          .then(response => {
            if (response.data.status === "OK") {
              this.errors = [];
              this.authenticator.mfa.verification_code = "";
              this.deactivateSubmitSpinner("verify_btn", 1);
              this.mfaAuthenticator = false;
              this.setLogin(this.form);
            } else {
              this.$snotify.error(response.data.errors);
              this.deactivateSubmitSpinner("verify_btn", 1);
            }
          })
          .catch(({ response }) => {
            this.$snotify.error(response.data.errors);
            this.deactivateSubmitSpinner("verify_btn", 1);
          });
      }

      setTimeout(() => {
        this.errors = [];
      }, 2000);
    },

    verifyEmailAuthenticator() {
      if (this.authenticator.email.verification_code === "") {
        this.errors = [];
        this.$snotify.error("Please Enter a Verification Code.");
      } else {
        this.errors = [];
        let data = this.form;
        data.verification_code = this.authenticator.email.verification_code;
        adminLoginService
          .verifyEmailVerificationCode(data)
          .then(response => {
            if (response.data.status === "OK") {
              this.errors = [];
              this.authenticator.email.verification_code = "";
              this.setLogin(this.form);
            } else {
              this.$snotify.error(response.data.errors);
              this.deactivateSubmitSpinner("verify_btn", 1);
            }
          })
          .catch(({ response }) => {
            this.errors = response.data.errors;
            this.deactivateSubmitSpinner("verify_btn", 1);
          });
      }

      setTimeout(() => {
        this.errors = [];
      }, 2000);
    },

    verifyMultiAuthCode() {
      if (this.verificationEmailBtn) this.verifyEmailAuthenticator();
      else this.verifyMfaAuthenticator();
    },

    requestEmailVerificationCode() {
      let data = this.form;
      adminLoginService
        .requestEmailVerificationCode(data)
        .then(response => {
          if (response.data.status === "OK") {
            this.errors = [];
            this.emailVerificationMailRequested = true;
          }
        })
        .catch(({ response }) => {
          this.emailVerificationMailRequested = false;
          this.errors = response.data.errors;
        });
      setTimeout(() => {
        this.emailVerificationMailRequested = false;
      }, 60000);
    }
  }
};
</script>
